import axios from "axios";

export const updateScreener = async (screenerId: string, data: any) => {
  return axios({
    method: "PUT",
    url: `/api/v1/screeners/${screenerId}`,
    withCredentials: true,
    data: data,
  });
};
