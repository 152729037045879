import { Button, CircularProgress } from "@mui/material";
import * as React from "react";
import TruncatedList from "../../pages/watchlists/components/TruncatedList";
import { useImmer } from "use-immer";
import axios from "axios";
import { cleanDomains } from "../../pages/watchlists/components/utils";
import { createContext, useContext } from "react";
import { RemoveCircle } from "@mui/icons-material";

import ModalComponents from "../Modal";
import { ToastContext } from "../../providers/ToastProvider";
import { AlertColor } from "@mui/material/Alert";

const RemoveFromScreenerModal = ({
  screenerId,
  domainNames,
  open,
  onClose,
  refreshData,
}: {
  screenerId: string;
  domainNames: string[];
  open: boolean;
  onClose: () => void;
  refreshData: () => void;
}) => {
  const { setSuccess, setMessage, setError } = useContext(ToastContext);

  const [state, setState] = useImmer<{
    locked: boolean;
  }>({
    locked: false,
  });

  const onSubmit = async (domainNames: string[], onClose: () => void) => {
    setState((state) => {
      state.locked = true;
    });

    axios({
      method: "DELETE",
      url: `/api/v1/screeners/${screenerId}/entries`,
      data: {
        domain_names: cleanDomains(domainNames),
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data["toast"]) {
          setMessage(
            response.data["toast"]["message"],
            response.data["toast"]["severity"] as AlertColor
          );
        } else {
          setSuccess("Successfully removed from screener");
        }
        refreshData();
        onClose();
      })
      .catch((error) => {
        setError("Unable to remove from screener");
      })
      .finally(() => {
        setState((state) => {
          state.locked = false;
        });
      });
  };

  return (
    <ModalComponents.ModalContainer open={open} onClose={onClose}>
      <ModalComponents.ModalPage
        title={"Are you sure?"}
        body={
          <>
            <ModalComponents.ModalBodyText
              content={
                <>
                  <p>
                    Are you sure you no longer want to track these companies?
                  </p>
                  {/*<p>*/}
                  {/*  {organization.credits.monthlyRemoves} / {organization.credits.granted} monthly removals remaining (Resets beginning of each month).*/}
                  {/*</p>*/}
                </>
              }
            />
            <TruncatedList
              domains={domainNames}
              maxRows={8}
              icon={<RemoveCircle color="error" />}
            />
          </>
        }
        buttons={
          <>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="error"
              variant="outlined"
              disabled={state.locked}
              onClick={() => {
                onSubmit(domainNames, onClose);
              }}
            >
              {state.locked ? (
                <>
                  Confirm <CircularProgress color="inherit" size={16} />
                </>
              ) : (
                "Confirm"
              )}
            </Button>
          </>
        }
      />
    </ModalComponents.ModalContainer>
  );
};

export type RemoveFromScreenerModalContextValue = {
  show: (
    screenerId: string,
    domains: string[],
    refreshData?: () => void,
    redirect?: boolean
  ) => void;
};

export const RemoveFromScreenerModalContext =
  createContext<RemoveFromScreenerModalContextValue>({
    show: (
      screenerId: string,
      domains: string[],
      refreshData?: () => void,
      redirect?: boolean
    ) => {},
  });

const RemoveFromScreenerModalProvider = ({ children }: { children: any }) => {
  const [state, setState] = useImmer<{
    open: boolean;
    onClose: () => void;
    screenerId: string;
    domains: string[];
    refreshData: () => void;
    redirect: boolean;
  }>({
    open: false,
    onClose: () => {},
    screenerId: "",
    domains: [],
    refreshData: () => {},
    redirect: false,
  });

  const context = {
    show: (
      screenerId: string,
      domains,
      refreshData?: () => void,
      redirect?: boolean
    ) => {
      setState((state) => {
        state.screenerId = screenerId;
        state.domains = domains;
        state.refreshData = refreshData || (() => {});
        state.redirect = redirect || false;
        state.open = true;
      });
    },
  } as RemoveFromScreenerModalContextValue;

  return (
    <RemoveFromScreenerModalContext.Provider value={context}>
      {children}
      <RemoveFromScreenerModal
        open={state.open}
        onClose={() => {
          setState((state) => {
            state.open = false;
            state.screenerId = "";
            state.domains = [];
          });
        }}
        refreshData={state.refreshData}
        screenerId={state.screenerId}
        domainNames={state.domains}
      />
    </RemoveFromScreenerModalContext.Provider>
  );
};

export default RemoveFromScreenerModalProvider;
