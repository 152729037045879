import { createContext, ReactNode, useEffect } from "react";

import { useImmer } from "use-immer";
import { useMediaQueryContext } from "./MediaQueryProvider";

export type UserSettings = {
  menuExpanded: boolean;
};

export type UserSettingsContextValue = UserSettings & {
  setMenuExpanded: (isExpanded: boolean) => void;
  clear: () => void;
};

export const UserSettingsContext = createContext<UserSettingsContextValue>({
  menuExpanded: false,
  setMenuExpanded: () => {},
  clear: () => {},
});

const LS_KEY = "fintent-user-settings";

const UserSettingsProvider = ({ children }: { children: ReactNode }) => {
  const { isLowRes } = useMediaQueryContext();

  const loadUserSettings = () => {
    return JSON.parse(localStorage.getItem(LS_KEY) || "{}") as UserSettings;
  };

  const clear = () => {
    localStorage.removeItem(LS_KEY);
  };

  const [state, setState] = useImmer<{
    userSettings: UserSettings;
  }>({
    userSettings: loadUserSettings(),
  });

  const setMenuExpanded = (expanded: boolean) => {
    setState((state) => {
      state.userSettings.menuExpanded = expanded;
    });
  };

  // Load the state from localStorage when the component mounts
  useEffect(() => {
    const savedState = loadUserSettings();
    savedState.menuExpanded = isLowRes ? false : savedState.menuExpanded;
    if (savedState) {
      setState((state) => {
        state.userSettings = savedState;
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(LS_KEY, JSON.stringify(state.userSettings));
  }, [state.userSettings.menuExpanded]);

  const context = {
    setMenuExpanded: setMenuExpanded,
    clear: clear,
    ...state.userSettings,
  };

  return (
    <UserSettingsContext.Provider value={context}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsProvider;
